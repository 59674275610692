import * as React from "react"
import { Helmet } from "react-helmet"
import Policy from "../components/policySection"
import "../policy.css"
// import 'semantic-ui-css/semantic.min.css';
import { useIntl } from "gatsby-plugin-intl";



const PrivacyPage = () => {
    const intl = useIntl();
    const privacy = intl.messages.privacy;
    return (
        <>
        <Helmet>
            <html className="policy"  />
        </Helmet>
        <div id="privacy">
            <Policy message={privacy} />
        </div>
        </>
    )
}

export default PrivacyPage
